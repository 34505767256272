.notice-bar {
    position: relative;
    height: 50px;
    
    p {
        margin-bottom: 0;
    }
}

.notice-bar-header-section {
    top: -56px;
    background-color: map-get($theme-colors, black-);
    color: map-get($theme-colors, white-);
}

.notice-bar-footer-section {
    width: 90%;
    background-color: map-get($theme-colors, teal-light-1);
    color: map-get($theme-colors, black-);
    margin: 0 auto;
    border-top-right-radius: 2.5rem;
    border-top-left-radius: 2.5rem;
    font-weight: bold;
}