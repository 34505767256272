.navbar {
    // padding: 1.5rem 2rem!important;
}
.navbar-light .navbar-nav .nav-link {
    color: map-get($theme-colors, black-);
    padding: 0 1.25rem;
}

.navbar-brand {
    background: url("../images/header/logo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 160px;
    height: 32px;
}

.navbar-nav {

    .icon__check_balance {
        background: url("../images/icons/card.svg");
        background-size: 20px auto;
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 20px;
    }

    .icon__cart{
        background: url("../images/icons/cart.svg");
        background-size: 20px auto;
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 20px;
        margin-left: 1.5rem;
    }
}

.breadcrumb-item a {
    color: map-get($theme-colors, black-);
    text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    width: 22px;
    top: 0px;
    position: relative;
}

