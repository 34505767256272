.check-balance-section {
    @include media-breakpoint-down(sm) { 
        background-position: -50%,150%;
    }
    @media (max-width: 414px) {
        background-position: -100%,200%;
        background-size: 65%;
    }

    .check-balance-section__para {
        max-width: 450px;
        width: 100%;
    }
}

.balance-container {
    padding-top: 3.5rem;
    padding-bottom: 2.5rem;
    @include media-breakpoint-down(md) { 
        padding-top: 1rem;
        padding-bottom: 0rem;
    }
}

.custom-card__container {
    overflow: hidden;
    @include media-breakpoint-down(md) { 
        padding: 0 1.5rem;
    }
}
.custom-card__outer {
    margin: 3rem 0;
    @include media-breakpoint-down(md) { 
        margin: 1rem 0;
    }
}
.custom-card {
    border-radius: 0.75rem;

    .card-body {
        padding-top: 75%;
    }
}

.custom-card__pageBottom {
    border-radius: 2.5rem;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
    transform: translateY(3rem);
    margin: 0 20px;
    @include media-breakpoint-down(md) { 
        transform: translateY(1.5rem);
    }
}

.custom-card__white {
    background: map-get($theme-colors, white-);
    box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.1);
}

.custom-card__teal {
    background:map-get($theme-colors, teal-light-1);
}

.custom-card__under {
    z-index: 1;
}
.custom-card__over {
    z-index: 2;
}

.custom-card__offsetleft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @include media-breakpoint-down(md) { 
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        margin: 0;
    }
}

.custom-card__teal__bg {
    position: absolute;
    max-width: 76.33333%;
    width: 100%;
    background: #DFFCF4;
    height: 100%;
    max-height: 500px;
    left: -25%;
    top: 0;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    z-index: 1;

    @include media-breakpoint-down(lg) { 
        max-width: 100%;
        left: 0;
        max-height: 600px;
    }
}

.custom-card__offsetright {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-card__rightOverlap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    overflow: hidden;
    @include media-breakpoint-down(md) { 
        position: static;
        width: 100%;
        margin-top: -2.5rem;

    }
}

.custom-card__offsetright--widthWrap {
    max-width: 600px;
    margin: 0 auto 0 25%;
    padding-top: 3rem;
    @include media-breakpoint-down(md) { 
        margin: 0 auto;
        padding-top: 0rem;
    }
}

.custom_card__over--posModify {
    margin-top: 130px;
    margin-left: -25%;
    float: left;
    min-height: 360px;
    z-index: 10;
    @include media-breakpoint-down(lg) { 
        margin: 0 auto;
        float: none;
    }
    @include media-breakpoint-down(md) { 
        margin-top: -2rem;

    }
}

.custom-card__image {
    width: 100%;
}

.custom-card__image--overlap {
    position: absolute;
    top: 0;
    transform: scale(1.05);
}

.custom-card__imgContainer {
    display: flex;
    height: 64px;
    padding: 0 0.5rem 0 0;
    margin-top: 2rem;
    > img {
        align-self: center;
    }
    @include media-breakpoint-down(md) { 
        height: auto;
    }

}

.transactions-container {
    position: relative;
}

.custom-card-form {
    max-width: 420px;
    width: 100%;
    margin: 0 0 0 auto;
    @include media-breakpoint-down(md) { 
        margin: 0;
    }

    .custom-card-form__input {
        min-width: 160px;
    }

    &.was-validated .form-control:invalid, .form-control.is-invalid {
        background-image: none;
    }
    &.was-validated .form-control:valid {
        background-image: none;
    }
}

.form-control__custom {
    border-color: #949494;
}

.custom-card__giftcard {
    @include media-breakpoint-down(md) { 
        padding-bottom: 1rem;
    }
}

.gift-card-summary__rowBorder {
    border-bottom: solid 1px #000;
    margin-bottom: 1rem;
}

.logout-btn__container{
    position: absolute;
    bottom: 115%;
    right: 0;
    button {
        min-width: 240px;
    }
    @include media-breakpoint-down(lg) { 
        position: static;
        margin: 2rem 0;
        button {
            width: 100%;
        }
    }
}