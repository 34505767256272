table{
    font-family: "FuturaPT-Book";
    th ,.table-responsive-stack-thead{
        font-family: "MazzardH-Bold";
    }
    td {
        font-family: "FuturaPT-Book";
        font-size: 1.125rem;
    }
}

.gift-card-summary__table {
    z-index: 5;

    @include media-breakpoint-down(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


.gift-card-summary__tableContent {

    td,
    th {
        padding: 1rem 2rem 1rem 0;
    }
}

.transaction-history-table {
    padding: 3rem;
    @include media-breakpoint-down(md) {
        padding: 1.5rem 2rem 0.5rem;
    }
    tbody{
        > tr {
            border-top-width: 1px;
        }
    }
    tr {
        font-size: 0.875rem;
    }

    td,
    th {
        padding: 1rem 1rem 1rem 0;
        border-bottom: 0;
    }

    td {
        padding-bottom: 2rem;
    }
}

.table-responsive-stack tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}


.table-responsive-stack td,
.table-responsive-stack th {
    display: block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1rem 1rem 0;
}

.table-responsive-stack td:nth-of-type(3),
.table-responsive-stack th:nth-of-type(3) {
    flex-basis: 25%!important;
}

.table-responsive-stack td:nth-of-type(4),
.table-responsive-stack th:nth-of-type(4) {
    flex-basis: 15%!important;
}

.table-responsive-stack td:nth-of-type(5),
.table-responsive-stack th:nth-of-type(5) {
    flex-basis: 15%!important;
}


@media screen and (max-width: 768px) {
    .table-responsive-stack {
        margin-top: 1.5rem;
    }
    .table-responsive-stack tr {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        display: block;
        padding: 0.5rem 0;
    }

    .table-responsive-stack .table-responsive-stack-thead {
        font-weight: bold;
        float: left;
        width: 45%;
    }
}