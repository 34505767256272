.footer-section {
    margin-top:3rem;

    @include media-breakpoint-down(md){
        margin-top:1.5rem;
      }
}

.footer {
    background-color: map-get($theme-colors, black-);
    color: map-get($theme-colors, white-);
    border-top-right-radius: 2.5rem;
    border-top-left-radius: 2.5rem;
}

.footer__logo {
    @include media-breakpoint-down(lg) { 
    display: block;
    width: 100%;
    }
}

.footer__brand {
    background: url("../images/footer/logo.svg");
    background-size: 100%;
    width: 160px;
    height: 32px;
    display: inline-block;
    margin-top: 6px;
    background-repeat: no-repeat;

    @include media-breakpoint-down(md){
        width: 145px;
      }
    
      @include media-breakpoint-down(sm){
        width: 130px;
      }
}

.footer__links {

    .footer__links_title {
        margin-bottom: 2rem;
        opacity: .6;

        @include media-breakpoint-down(md){
            margin-bottom: 1.25rem;
        }
    }

    p{
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(md){
            margin-bottom: 0.2rem;
        }
    }

    a {
        color:map-get($theme-colors, white-);
        text-decoration: none;
        padding-left: 0!important;
        font-size: 1em;
        padding-top: 0;
        &:hover{
            color:map-get($theme-colors, white-);
        }
    }

    .payment_section {
        margin-bottom: 3rem;
    }

    .payment {
        text-align: right;

        @include media-breakpoint-down(lg) { 
            text-align: left;
            padding-top: 1rem;
         }

         .payment__text{
            display: inline-block;
            margin-bottom: 0;
            text-align: left;
            padding-right: 1.2rem;

            @include media-breakpoint-down(lg) { 
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: block;
             }
        }
    }

    .payment__list {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        max-width: 480px;

        li{
            display: inline;
            padding-bottom: 0.5rem;
            padding-right: 0.6rem;
            width: 100%;

        }

        img {
            @include media-breakpoint-down(md) { 
                height: 30px;
                width: auto;
             }
        }
    }

    .footer__links_section {
        max-width: 1200px;

        .nav-link {
            // color:  map-get($theme-colors, black-)!important;
            border: 2px solid transparent;
            padding:0;
            display: inline-block;
            
            &:hover {
                text-decoration: underline;
            }
            
            &:active {
                text-decoration: underline;
            }
            &:disabled {
                pointer-events: all !important;
                opacity: 0.5;
            }
        
            @include media-breakpoint-down(md){
              padding: 0.5rem 0;
            }
            
        }
    }

    .icon__store_locator {
        position: relative;
        left: 0.5rem;
    }

    .copyright {
        opacity: 0.6;
    }
}