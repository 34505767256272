// shop/hero-banner.html

.hero-banner-section {
    position: relative;
    background:url('../images/check-balance-transactions/bg-pattern-left.svg'), url('../images/check-balance-transactions/bg-pattern-right.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0,100%;
    padding: 3rem 0;
    /*min-height: 360px;*/
    display: grid;
    place-items: center;

    @include media-breakpoint-down(sm) { 
        background-position: -50%,150%;
    }
    @media (max-width: 414px) {
        background-position: -100%,200%;
        background-size: 65%;
    }

}


// how-it-works/hero-banner-2col-text-img.html

.hero_banner_2col_section{
    position: relative;
}

.hero_banner_2col__background {
    position: absolute;
    width: 50%;
    background: map-get($theme-colors, teal-primary);
    height: 100%;
    max-height: 384px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;

    @include media-breakpoint-down(md) { 
        top: 40%;
        max-height: 370px;
        width: 100%;
    }

    @include media-breakpoint-down(sm) { 
        top: 33%;
        max-height: 170px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
}

.hero_banner_2col-item__img {
    width: 100%; 
    max-width: 490px;
    z-index: 1;

    @include media-breakpoint-down(md) { 
        margin-bottom: 2rem;     
     }
}

.hero_banner_2col-item__text {
    grid-column-start: 2;
    grid-column-end: five;
    grid-row-start: row1-start;
    grid-row-end: 4;
    align-self: start;
}