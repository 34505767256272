.product-card {
    position: relative;
    z-index: 1;
    margin-bottom: .75rem;
}
.product-card__background {
    position: relative;
    background: map-get($theme-colors, teal-light-1);
    background-size: 100%;
    width: 100%;
    height:100%;
    background-repeat: no-repeat;
    border-radius:1rem;
    z-index: -1;
    margin-bottom: 2rem;
    padding: 2.25rem;
    
    @include media-breakpoint-down(sm) { 
        padding: 1.25rem;
        border-radius:0.5rem;
        margin-bottom: 1rem;
    }
}

.product-card__promo_home {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -17px;
    background: #FFD700;
    text-align: center;
    padding: .5rem;
    border-radius: 0.25rem;
    width: 90%;
    margin: 0 auto;
    z-index: 10;
    display: grid;
    place-items: center;
    font-size: 0.75rem;

    @include media-breakpoint-down(xl) { 

    } 

    @include media-breakpoint-down(sm) { 

    }    
}

.product-card__promo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -17px;
    background: #FFD700;
    text-align: center;
    padding: 0.19rem .5rem;
    border-radius: 0.25rem;
    width: 90%;
    margin: 0 auto;
    z-index: 10;
    min-height: 24px;
    display: grid;
    place-items: center;
    font-size: 0.75rem;

    @include media-breakpoint-down(xl) { 
        font-size: .7rem;
    }  
    @include media-breakpoint-down(md) { 
        font-size: .55rem;
    } 
}

.product-card__multiple_designs {

    background: map-get($theme-colors, white-);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    text-align: center;
    border-radius: 1rem;
    width: auto;
    margin: 0 auto;
    line-height: 2;
    padding: 0 5px;
    box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.1);
    white-space: nowrap;

    .product-card__multiple_designs_end {
        display: none;
    }
}

.product-card__multiple_designs .icon::before {
    content: "";
    background-image: url("../images/icons/multiple-design.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: relative;
    top:4px;
    display: inline-block;
    margin-right: 4px;
}

.product-card__img {
    position: relative;
}

.product-card__shadow {
    position: absolute;
    width: 55%;
    height:45%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    box-shadow: 0px 11px 20px 5px rgba(0,0,0,0.4);

    @include media-breakpoint-down(sm) { 
        box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.2);
    }
}

.product-card__brand {
    margin-bottom: .75rem;
    font-weight: bold;   
    margin-top: 1.3rem;
}

.product-card__spend {
    position: relative;
    padding-left: 2rem;
    max-width: 85%;
    word-break: break-word;

    @include media-breakpoint-down(sm) {
        padding-left: 1.5rem;
        font-size: 0.875rem;
     }

    &:before{
        position: absolute;
        left: 0px;
        top: 12px;

        @include media-breakpoint-down(sm) {
            font-size: 0.9rem;
        }
    }
}

.product-card {
    padding: 0.25rem;
    border: 2px solid transparent;

    .product-card__img {
        top: -3px;
        transform: scale(1);
        transition: transform 0.3s ease-in-out;
    }
  
    &:hover {
      border: 2px solid map-get($theme-colors, black-);
      border-radius: 15px;

      .product-card__img {
          transform: scale(1.05);
          transition: transform 0.15s ease-in-out;
      }
      .product-card__brand {
          text-decoration: underline;
      }
    }  
  }

  .product-card__spend.gift-card-detail{
    max-width: 100%;
}