.content-feature-section {
    background: url(../images/content-feature-background.svg);
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-down(md) { 
        background-size: 1150px;
        background-repeat: no-repeat;
        background-position: unset;
        background-position-x: -70px;
    }
}