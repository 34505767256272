.navbar {
  margin-top: -56px;
  top: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'FuturaPT-Medium';
  background-color: map-get($theme-colors, teal-bg);
  position: relative;

  @include media-breakpoint-down(md) {
    position: fixed;
    padding-top: 0.41rem;
    padding-bottom: 0.41rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.navbar-brand {
  @include media-breakpoint-down(md) {
    width: 120px;
    top: 5px;
    left: 15px;
    position: relative;
  }

  // @include media-breakpoint-down(sm){
  //   width: 100px;
  //   top: 8px;
  // }
}

.navbar-toggler {
  position: relative;
  width: 27px;
  height: 30px;
  background: none;
  border: none;
  padding: 0;
  left: 8px;
  top: -4px;
  @include media-breakpoint-down(md) {
    top: 0px;
  }
}

.navbar-toggler .shape-bar:nth-of-type(2) {
  top: 1px;
}

.navbar-toggler .shape-bar:nth-of-type(3) {
  top: 7px;
}

.navbar-toggler .shape-bar {
  position: relative;
  transition: all 250ms ease-in-out;
  width: 25px;
  height: 2px;
  background: map-get($theme-colors, black-);
  display: block;
  border-radius: 10px;
  top: -5px;
}

.navbar-toggler.active .shape-bar:nth-of-type(1) {
  top: 0px;
  left: -5px;
  transform: rotate(45deg);
}

.navbar-toggler.active .shape-bar:nth-of-type(2) {
  background-color: transparent;
}

.navbar-toggler.active .shape-bar:nth-of-type(3) {
  top: -4px;
  left: -5px;
  transform: rotate(-45deg);
}

.nav-item {
  padding: 0 1.25rem;

  @include media-breakpoint-down(md) {
    padding: 0 0.5rem;
  }

  .icon-cart.nav-link:before {
    @include media-breakpoint-down(md) {
      padding: 0;
      font-size: 1.5rem;
    }
  }
}

.nav-item__bg {
  height: 55vh;
}

.navbar-nav_icons .nav-item {
  padding: 0;
  position: relative;
}

.navbar-light .navbar-nav .nav-link {
  color: map-get($theme-colors, black-) !important;
  border: 2px solid transparent;
  padding: 0;
  font-size: 1.125rem;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }
  &:disabled {
    pointer-events: all !important;
    opacity: 0.5;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 0;
    &:focus {
      padding: 0.5rem 0;
    }
  }
}

.navbar-collapse {
  @include media-breakpoint-down(md) {
    // margin-top: 2rem;
    width: 100%;
  }
}

.sticky {
  position: fixed;
  top: 0;
  margin-top: 0;
}

.navbar-nav_cart a {
  margin-right: 0 !important;
}

.nav-bg {
  background: url('../images/background-pattern-nav.png');
  background-size: contain;
  width: 105%;
  background-repeat: no-repeat;
  background-position: center right;
  height: 45vh;
  position: absolute;
}
