.breadcrumb {
    background: none!important;
}

.breadcrumb-item {
    padding: 0 0.5rem;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    top: 4px;
    margin-right: 0.5rem;
    position: relative;
}

.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0rem;
    color: #6c757d;
    content:"";
    background:url(../images/chevron-right.png) ;
    width: 9px;
    height: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.breadcrumb-item .nav-link {
    display: inline;
    padding: 0.5rem 0;
    border: 2px solid transparent;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        text-decoration: underline;
    }
    &:disabled {
        pointer-events: all !important;
        opacity: 0.5;
    }
}