button {
  font-family: 'MazzardH-Bold';
}

.btn {
  margin: 0.5rem 0 0.5rem 0;
  width: 100%;
  max-width: 303px;
  font-family: 'MazzardH-Bold';
  left: -2px;
  position: relative;

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    left: 0;
  }
  p {
    font-family: 'MazzardH-Bold';
  }
}

.btn_primary {
  border: 2px solid transparent;
  border-radius: 15px;
  padding: 2px;

  &:hover {
    border: 2px solid map-get($theme-colors, black-);
    border-radius: 15px;
    padding: 2px;
  }

  p {
    border: 1px solid map-get($theme-colors, black-);
    height: 56px;
    border-radius: 12px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    font-weight: bold;
    background-color: map-get($theme-colors, black-);
    color: map-get($theme-colors, white-);

    @include media-breakpoint-down(sm) {
      padding: 1rem 0.5rem;
    }

    &:hover {
      background-color: map-get($theme-colors, grey-);
    }
  }
}
.btn-outline-primary:hover,
.btn-outline-seccondary:hover {
  background: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background: none;
  border-color: map-get($theme-colors, black-);
}

.btn_secondary {
  border: 2px solid transparent;
  border-radius: 15px;
  padding: 2px;

  &:hover {
    border: 2px solid map-get($theme-colors, black-);
    border-radius: 15px;
    padding: 2px;
  }

  p {
    border: 1px solid map-get($theme-colors, black-);
    height: 56px;
    border-radius: 12px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    font-weight: bold;
    color: map-get($theme-colors, black-);

    @include media-breakpoint-down(sm) {
      padding: 1rem 0.5rem;
    }

    &:hover {
      background-color: #cccccc;
    }
  }
}

// CAROUSEL NAV BUTTONS

.btn_left {
  background-color: map-get($theme-colors, white-);
  border: none;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  height: 48px;
  width: 48px;
  margin-right: 0.5rem;
  transition: all 0.2s ease-out;

  &::before {
    left: -2px;
    position: relative;
    top: 5px;
    padding: 0;
  }

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    background-color: map-get($theme-colors, teal-light-2);
    border: none;
    bottom: 0;
  }
  &:disabled,
  &.hidden {
    pointer-events: none !important;
    opacity: 0.5;
  }
}

.btn_right {
  background-color: map-get($theme-colors, white-);
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 25px;
  height: 48px;
  width: 48px;
  margin-left: 0.5rem;
  transition: all 0.2s ease-out;

  &::before {
    left: -2px;
    position: relative;
    top: 5px;
    padding: 0;
  }

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    background-color: map-get($theme-colors, teal-light-2);
    border: none;
    bottom: 0;
  }

  &:disabled,
  &.hidden {
    pointer-events: none !important;
    opacity: 0.5;
  }
}

.btn-outline-primary,
.btn-outline-seccondary {
  color: inherit;
}

.btn-outline-primary:hover,
.btn-outline-secondary:hover {
  color: inherit;
  background-color: inherit;
  // border-color: inherit;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: inherit;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background: inherit;
  border-color: inherit;
}

.btn-shadow {
	-moz-box-shadow:    1px 1px 5px 1px #ccc;
	-webkit-box-shadow: 1px 1px 5px 1px #ccc;
	box-shadow:         1px 1px 5px 1px #ccc;
}

.btn-block {
  max-width: 100%;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
