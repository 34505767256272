.content-4col__background {
    background: map-get($theme-colors, teal-light-1);
    background-size: 100%;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    margin: 0 auto;

    img {
        position: relative;
        width: 65%!important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.content-4col__title {
    margin: 3rem 0 1.25rem 0;
    text-align: center;
    min-height: 2.5rem;
    align-items:center;
    justify-content:center;
    display: flex;

    @include media-breakpoint-down(sm) { 
        margin: 1.5rem 0 0.75rem 0;
    }
  }

.content-4col__p {
    text-align: center;
}