.content-2col-2cards-section {
    background: map-get($theme-colors, teal-light-1);
    width: 100%;
}

.content-2col-2cards__container {

    background: white;
    border-radius: 25px;
    box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.1);
    height: 100%;

    .content-2col-2cards {
        padding: 3rem;
        padding-bottom: 0;

        @include media-breakpoint-down(sm) { 
            padding: 1.5rem;
        }
    }

    .row {
        padding: .8rem 0 0.15rem 0;
        color:map-get($theme-colors, grey-);

        &:last-child {
            border-bottom: none;
        }
    }

    .col-5 p {
        font-weight: bold;
        padding-right: 1rem;
        
    }

    ul li {
        color: map-get($theme-colors, teal-primary);
        list-style-type: disc;
        line-height: 1.8rem;
        padding-bottom: 1rem;
    }
    ul li span {
        /* Text color */
        color: map-get($theme-colors, black-);
    }

    .content-2col-2cards__disclaimer {
        position: relative;
        bottom: -1rem;
        padding: 2rem 3rem 2rem 3rem;
        background-color: #F5F5F5;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;        
    }
}