.proof-points__background {
    background: map-get($theme-colors, teal-light-1);
    background-size: 100%;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto;

    @include media-breakpoint-down(sm) { 
        width: 120px;
        height: 120px;
    }

    img {
        position: relative;
        width: 75%!important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.proof-points__title {
    text-align: center;
    margin: 1.5rem 0 2rem 0;
    @include media-breakpoint-down(md) { 
        width: 100%;
        max-width: 135px;
        margin-right: auto;
        margin-left: auto;
    }
}

.proof-points {
    @include media-breakpoint-down(md) { 
        padding-top: 1.5rem;
        text-align: center;
    }
}