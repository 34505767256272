html { font-size: 16px }

@import url("//hello.myfonts.net/count/3d7d3b");
  
@font-face {
  font-family: "MazzardH-Bold";
  src: url('../fonts/webFonts/MazzardHBold/font.woff2') format('woff2'), url('webFonts/MazzardHBold/font.woff') format('woff');
}
@font-face {
  font-family: "FuturaPT-Book";
  src: url('../fonts/webFonts/FuturaPTBook/font.woff2') format('woff2'), url('webFonts/FuturaPTBook/font.woff') format('woff');
}
@font-face {
  font-family: "FuturaPT-Medium";
  src: url('../fonts/webFonts/FuturaPTMedium/font.woff2') format('woff2'), url('webFonts/FuturaPTMedium/font.woff') format('woff');
}


$font-size-desktop: (
    "body-1":1.125rem,
    "body-2":1rem,
    "body-3":0.65rem,
    "button":1rem,
    "navigation":1.125rem
);

$font-size-mobile: (
    "body-1":1rem,
    "body-2":0.875rem,
    "body-3":0.65rem,
    "button":0.875rem,
    "navigation":0.875rem
);

h1.carousel_title, h1, h2, h3, h4, h5, h6 , 
.heading-text-1, .heading-text-2, .heading-text-3, 
.heading-text-4, .heading-text-5, .heading-text-6 {
    font-family: "MazzardH-Bold";
    line-height: 1.2;
}

p,.body-text-medium, .body-text-small, .body-text-xsmall {
    font-family: "FuturaPT-Book";
} 
.body-text-bold {
  font-family: "MazzardH-Bold";
}
@media (min-width: 768px) {  

    h1.carousel_title { font-size:4.5rem; line-height: 1.0;}
    h1 { font-size:4rem; line-height: 1.0;}
    h2 { font-size:3rem; }
    h3 { font-size:2rem; }
    h4 { font-size:1.5rem; }
    h5 { font-size:1.125rem; }
    h6 { font-size:0.875rem; }    

    p,.body-text-medium {font-size:map-get($font-size-desktop, body-1);}
    .body-text-small  {font-size:map-get($font-size-desktop, body-2);}
    .body-text-xsmall {font-size:map-get($font-size-desktop, body-3);}

    .heading-text-1 {font-size:4rem;}
    .heading-text-2 {font-size:3rem;}
    .heading-text-3 {font-size:2rem;}
    .heading-text-4 {font-size:1.5rem;}
    .heading-text-5 {font-size:1rem;}
    .heading-text-6 {font-size:0.75rem;}
  }

@media (max-width: 767px) {  

    h1 {font-size:2.5rem;} 
    h2 {font-size:2rem;}
    h3 {font-size:1.625rem;}
    h4 {font-size:1.25rem;}
    h5 {font-size:1rem;}
    h6 {font-size:0.75rem;}

    p,.body-text-medium {font-size:map-get($font-size-mobile, body-1);}
    .body-text-small  {font-size:map-get($font-size-mobile, body-2);}
    .body-text-xsmall {font-size:map-get($font-size-mobile, body-3);}

    .heading-text-1 {font-size:2.6rem;} 
    .heading-text-2 {font-size:1.8rem;}
    .heading-text-3 {font-size:1.625rem;}
    .heading-text-4 {font-size:1.25rem;}
    .heading-text-5 {font-size:0.875rem;}
    .heading-text-6 {font-size:0.6875rem;}
}

@include media-breakpoint-down(sm) {
  h1 {font-size:2.6rem;} 
  h2 {font-size:1.8rem;}
  h3 {font-size:1.325rem;}
  h4 {font-size:1.25rem;}
  h5 {font-size:1.1rem;}
  h5.product-card__brand {font-size:0.875rem!important;}
  #shop-page h5 {font-size:0.875rem!important;}
  h6 {font-size:0.65rem;}
}


