.gift-card-detail__background {

    position: absolute;
    max-width: 53.33333%;
    width: 100%;
    background: map-get($theme-colors, teal-light-1);
    height: 100%;
    max-height: 595px;
    right: 0;
    top: 255px;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;

    @include media-breakpoint-up(xxl) {
        // max-height: 540px;
    }

    @include media-breakpoint-down(md) {
        // top: -30px;
        // max-width: 100%;
        // max-height: 610px;
        display: none;

    }
}
.gift-card-detail__background2 {
    position: absolute;
    width: 106%;
    background: #DFFCF4;
    height: 100%;
    max-height: 650px;
    top: -13px;
    left: -3%;
    border-radius: 2rem;
    display: none;
    @include media-breakpoint-down(md) {
        // top: -30px;
        // max-width: 100%;
        // max-height: 610px;
        display: block;

    }
}

.gift-card-detail-section {
    background: url("../images/content-background.svg");
    background-position: 250px bottom;
    background-repeat: no-repeat;

    hr {
        margin: 2.5rem 0;
    }
}

.gift-card-detail__choose_design {

    .gift-card-detail__design_list {
        padding: 0;

        .gift-card-detail__design_item {
            list-style: none;
            // margin: 0;
        }
    }

    .gift-card-detail__thumbnail {
        position: relative;
        display: block;
        background: map-get($theme-colors, teal-light-1);
        padding: 0.75rem;
        border-radius: 5px;
        margin: 0.25rem;
        border: 2px solid transparent;
        max-width: 22%;
        cursor: pointer;

        img {
            box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.1);
        }

        &:active,
        &:checked {
            border: 2px solid map-get($theme-colors, black-);

            &:before {
                content: "";
                background: url("../images/icons/tick2.svg");
                height: 20px;
                width: 20px;
                position: absolute;
                top: -13px;
                right: -8px;
            }
        }

    }
}

.gift-card-detail__promo {
    background: map-get($theme-colors, yellow-);
    border-radius: 5px;
    padding: 0.40rem 0;
}

.gift-card-detail__right_col {

    
    // 
    @include media-breakpoint-down(md) {
        position: relative;
    }    
}

.gift-card-detail__right_col .clickable {
    cursor: pointer;
}

.gift-card-detail__which_card {
    background: map-get($theme-colors, white-);
    box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.which_card_type__label {
    height: 100%;
    display: block;
    border: 1px solid #D8D8D8;
    position: relative;
}

#gift-card-detail__digital .which_card_type__label {
    border-left: 1px solid transparent;

    @media (max-width: 767px) {
        border-top: 1px solid transparent;
        border-left: 1px solid #D8D8D8;
    }
}

.which_card_type__input[type="radio"]:checked+.which_card_type__label {
    background: map-get($theme-colors, teal-light-1);
    color: map-get($theme-colors, black-);
    border: 1px solid map-get($theme-colors, black-) !important;
}

#card-value-5[type="text"]:focus {
    background: map-get($theme-colors, teal-light-1);
    color: map-get($theme-colors, black-);
    border: 2px solid map-get($theme-colors, black-) !important;
}

.which_card_type__input[type="radio"]:checked+.which_card_type__label h5::before {
    background: map-get($theme-colors, black-);
}

.which_card_type__input[type="radio"]:checked+.which_card_type__label h5 span::before {
    color: map-get($theme-colors, white-);
    visibility: visible;
}


.which_card_type__select_card_value_btn {

    border: 1px solid map-get($theme-colors, black-);
    border-radius: 7px;
    padding: 1.5rem 2rem;
    margin: .15rem;
    max-width: 110px;
    max-height: 50px;
    width: 100%;
    height: 100%;
    margin: 0 1.5% 2.5% 0;
    line-height: 0;

    &:active {
        background-color: map-get($theme-colors, teal-light-2);
        border: 2px solid map-get($theme-colors, black-);
    }

    @media (max-width: 767px) {
        // width: 25%;      
        margin: 0 1.5% 2.5% 0;
    }
}

.which_card_type__select_card_value_btn_wrapper {
    position: relative;
    font-family: "MazzardH-Bold";
    display: inline-block;

    &:before {
        position: absolute;
        content: '$';
        position: absolute;
        top: 23%;
        left: 35px;
        z-index: 1;
        display: none;
    }

    &.is-focused:before {
        display: block;
    }
}

.which_card_type__tick {
    position: relative;
    padding-left: 2rem;
    max-width: 100%;
    word-break: break-word;
    line-height: 1;

    &:before {
        position: absolute;
        left: 5px;
        top: 15px;
    }
}


.gift-card-detail__which_card_type {

    .which_card_type__label_title::before {
        content: "";
        width: 24px;
        height: 24px;
        position: relative;
        border: 2px solid black;
        display: inline-flex;
        border-radius: 25px;
        background-color: none;
        top: -8px;
        left: 1px;
        align-items: center;
        padding-left: 1rem;
        margin-right: 0.7rem;

        @include media-breakpoint-down(md) {
            top: -10px;
        }
    }

    .which_card_type__label_title {

        .icon-tick::before {
            position: absolute;
            color: map-get($theme-colors, white-);
            background-color: black;
            top: 35px;
            padding: 0;
            margin: 0;
            left: 30px;
            font-size: .9rem;
            visibility: hidden;

            @include media-breakpoint-down(md) {
                top: 34px;
            }

            @include media-breakpoint-down(sm) {
                top: 32px;
            }
        }
    }

    .which_card_type__label {
        padding-left: 2rem;
    }

    .which_card_type__input {
        opacity: 0;
    }

    .which_card_type__list {
        padding: 0;

        li {
            list-style: none;
            line-height: 1.75rem;
            margin: 0;

            &:before {
                color: map-get($theme-colors, validation-success);
                font-size: 0.9rem;
                margin-right: 0.35rem;
            }
        }
    }
}

.gift-card-detail__message {
    min-height: 150px;
    border-radius: 15px;
}

.btn-check {
    width: 0;

    &:checked+.which_card_type__select_card_value_btn {
        background-color: map-get($theme-colors, teal-light-2);
        border: 2px solid map-get($theme-colors, black-);
    }

    &:checked+.gift-card-detail__design_item,
    &:focus+.gift-card-detail__design_item {
        &:before {
            content: "";
            background: url("../images/icons/tick2.svg");
            height: 20px;
            width: 20px;
            position: absolute;
            top: -13px;
            right: -8px;
        }
    }
}

.gift-card-detail__decrease {
    border: 1px solid #949494;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 30px;
    width: 30px;
    background: none;
    position: relative;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    span::before {
        font-size: 0.8rem;
        top: 1px;
        left: 5px;
        position: relative;
        color: #949494;
    }


    &:hover {
        background-color: #000;

        span::before {
            color: #fff;
        }
    }

    &:disabled {
        opacity: 0.65;
    }
}

.gift-card-detail__increase {
    border: 1px solid #949494;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 30px;
    width: 30px;
    background: none;
    position: relative;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    span::before {
        font-size: 0.8rem;
        top: 1px;
        left: 5px;
        position: relative;
    }


    &:hover {
        background-color: #000;

        span::before {
            color: #fff;
        }
    }

    &:disabled {
        opacity: 0.65;
    }
}

.gift-card-detail__num_cards {
    width: 40px;
    height: 30px;
    padding: 0;
    border-left: none;
    border-right: none;
    border-top: 1px solid #949494;
    border-bottom: 1px solid #949494;
    border-radius: 0;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.optional {
    color: #666666;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #666666;
    opacity: 1;
    /* Firefox */
}

.gift-card-detail-section {

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
        background-image: none;
        padding-right: 2rem;
    }

    .was-validated .form-control:valid {
        background-image: none;
        padding-right: 2rem;
        border-color: #1a1a1a;
    }
}

.gift-card-detail__details ul {
    margin: 0;
    padding: 0;
}

ul.dashed {
    list-style-type: none;
}

ul.dashed>li {
    text-indent: -5px;
    padding-bottom: .5rem;
}

ul.dashed>li:before {
    content: "- ";
    text-indent: -5px;
}

.gift-card-detail__details_digital_subheading {
    font-size: 1rem;

    @include media-breakpoint-down(md) {
        font-size: 0.81rem;
    }
}

.gift-card-detail__add_to_cart_btn {
    max-width: 100%;
}

.gift-card-detail__details_digital {
    display: none;
}

.js-card-dollar {
    position: relative;

    &.is-active {
        &:before {
            content: '$';
            display: inline-block;
            position: absolute;
            top: 14px;
            font-family: 'MazzardH-Bold';
            left: 21px;
            z-index: 10;

        }
    }
}

.gift-card-detail__background {
    top: 205px;
}