.banner-2col-section__row {
    border-radius: 30px;
    background: map-get($theme-colors, white-);
    box-shadow: 0px 4px 12px 5px rgba(0,0,0,0.1);
    overflow: hidden;
}

.banner-2col__title{
    padding: 0 4rem;
}

.banner-2col-section__img {
    overflow: hidden;

    img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);      
        max-height: 600px;  

        @include media-breakpoint-down(md) { 
            max-height: 300px;  
            margin-top: 0.5rem;
        }
    }
}
