.show {
  display: block;
}

.filter__type_list {
  min-width: 270px;

  span {
    margin-bottom: 0;
  }
}

.filter__type_list li {
  float: left;
}

.filter__type_list, .filter__alphabet_list {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}
.filter__type_list li, .filter__alphabet_list li {
  margin: 0;
  padding: 0;
  border: 0;
  display: inline-block;
}
.filter__type_list li .filter__btn_type {
  margin: 0;
  padding: 0.4rem 1.0rem;
  border: 1px solid black;
  text-align: center;
  background: white;
  width: 100%;

  @include media-breakpoint-down(md) { 
    padding: 0.4rem 1.15rem;
  }
  
    
  &:hover, &:active, &.active{
    background: black;
    color: white;
  }
}

.filter__alphabet_list{
  text-align: justify;
  font-size: 0.1px; /* IE 9/10 fix */
}

#all-types {
  border-top-left-radius:0.5rem;
  border-bottom-left-radius:0.5rem;
  border-right:none;
}

#digital {
  border-top-right-radius:0.5rem;
  border-bottom-right-radius:0.5rem;
  border-left:none;
}

.filter__alphabet_letter {
  font-weight: bold;

  span {
    border: 2px solid transparent;
    border-radius: 20px;
    color: #1A1A1A;
    background-color: white;
    box-sizing: border-box;
    position: relative;
    min-width: 23px;
    height: 23px;
    padding:0.1rem;
    margin: 0 0.1rem;
    left: -8px;
    top: -3px;
    margin-bottom: 0;
    border-radius: 15px;
    
  }

  button {
    border: none;
    background: none;
    padding: 0;
    position: relative;
    top: 0;
    left: 6px;

    &:hover  {
      span {
        position: relative;
        border: 2px solid map-get($theme-colors, black-);
        border-radius: 15px;
        color: map-get($theme-colors, black-);
        background-color:map-get($theme-colors, white-);
        box-sizing:border-box;
      }
    }

    &:active, &.active {
      span {
        border: 2px solid transparent;
        background-color: map-get($theme-colors, black-);
        color:map-get($theme-colors, white-);
      }
    }
    &:disabled {
      span{
        border: 2px solid transparent;
        pointer-events: none !important;
        opacity: 0.3;
      }
    }
  }
}

.filter__alphabet_letter button.all-brand {
  span {
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    width: auto;
    height: 26px;
  }

  &:hover  {
    span {
      position: relative;
      border: 2px solid map-get($theme-colors, black-);
      border-radius: 5px;
      color: map-get($theme-colors, black-);
      background-color:map-get($theme-colors, white-);
      box-sizing:border-box;
    }
  }

  &:active, &.active {
    span {
      border: 2px solid transparent;
      background-color: map-get($theme-colors, black-);
      color:map-get($theme-colors, white-);
    }
  }
  &:disabled {
    span {
      border: 2px solid transparent;
      pointer-events: none !important;
      opacity: 0.7;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

// filter pagination nav
.card_list_pagination {
  .page-link{
    border: none;
    padding: 0 1rem;
    background: none;
    span {
      color: map-get($theme-colors, black-);      
    }
    &.disabled {
      opacity: 0.5;
    }
  }
}

.card_list_pagination__current_page_num {
  border-radius: 10px;
  border:1px solid map-get($theme-colors, black-);
  width: 50px;
  height: 50px;
  margin: 0 0.75rem;
  text-align: center;
}

// filter nav on mobile
@include media-breakpoint-down(md) { 
  .filter-container {
    position:fixed;
    bottom: 0;
    z-index: 9999;
    background: map-get($theme-colors, white-);
    overflow: auto;
    height: 100%;
    display: none;
  }

  .filter__alphabet_list .all-brands {
    display: block;
    float: initial;
    margin-bottom: 1rem;
    width: 110px!important;
    height: 26px!important;
  }

  .filter__alphabet_list li {
    // padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .close-filter {
    text-align: right;
  }

  .filter__alphabet_letter button {
    // width: 30px;
    // height: 30px;

    &.all-brand{
      width: auto;
    }
  }
  .filter__alphabet_letter__numbers {
    width: 60px!important;
  }
}

#hideFilter {
  border:none;
  background: none;
  margin-top: 5px;
  padding: 0;

  &:before {
    padding: 0;
  }
}

#clearAllFilters {
  color: map-get($theme-colors, black-);
  cursor: pointer;
  text-decoration: initial;
  
  &:hover{
    text-decoration: underline;
  }
}

#clearAllFilters:before {
    font-size: 0.8rem;
    top: 2px;    
}
