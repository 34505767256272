.carousel__background {
    position: absolute;
    width: 50%;
    background: map-get($theme-colors, teal-primary);
    height: 100%;
    max-height: 384px;
    right: 0;
    top: 43%;
    transform: translateY(-45%);
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;

    @media (min-width: 767px) and (max-width: 1024px){ 
      width: 40%;
    }

    @include media-breakpoint-down(md) { 
        top: 24%;
        max-height: 350px;
        width: 100%;
    }

    @media (max-width: 520px) {  
        top: 20%;
        width: 100%;
        max-height: 220px;
    }

    @media (max-width: 450px) {  
        top: 18%;
        max-height: 170px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    @media (max-width: 374px) {  
        top: 15.8%;
        max-height: 150px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }   
}

.carousel-inner {

    display: flex;
    align-items: center;
    min-height: 465px;
    margin-bottom: 2rem;

    @include media-breakpoint-down(lg) { 
         min-height: 635px;
     }

    @include media-breakpoint-down(md) { 
        position: relative;
        display: block;
        align-items: unset;
    }

     @include media-breakpoint-down(sm) { 
        margin-bottom: 1rem;
     }   
}

.carousel-item__text_col {
    @media (min-width: 767px) and (max-width: 1280px){ 
    //    background-color: red;
       padding-left: 2.2rem!important;    
       padding-right: 0!important;       
    }
}

.carousel-item__img_col {
    @media (min-width: 767px) and (max-width: 1280px){ 
    //    background-color: blue;
       padding-left: 3.5rem!important;        
    }
}

.carousel-item__img {
    width: 100%; 
    max-width: 490px;
}

.carousel-control-prev {
    .btn_left {        
        &::before {
            left: 0px;
            top: 18px;
            color:map-get($theme-colors, black-);
        }
    }
}

.carousel-control-next {
    .btn_right {        
        &::before {
            left: 0px;
            top: 18px;
            color:map-get($theme-colors, black-);
        }
    }
}
.carousel-controls {
    position: absolute;
    z-index: 2;
    padding: 0;
    list-style: none;
    margin: 1rem 0 1rem 0;
    right: initial;
    left: 0;
    bottom: 0;
    @include media-breakpoint-down(md) { 
        position: static;
        justify-content: center;
    }
}
.carousel-indicators {
    position: relative;
    top: 12px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    li {
    margin-right: 3px;
    margin-left: 3px;
    }
    @include media-breakpoint-down(md) { 
        position: relative;
        bottom: initial;
     }

     @include media-breakpoint-down(sm) { 
        transform: scale(1.1);
     }
}

.carousel-control-prev, .carousel-control-next {

    opacity: 1;
    width: 8%;

    &:hover {
        opacity: 1; 
    }

    &:focus {
        opacity: 1;        
    }

    &:active {
        opacity: 1; 
    }

    @include media-breakpoint-down(md) { 
        top:25%;
        align-items: baseline;
        width: 25%;
     }

     @media (max-width: 520px) {  
        top: 20%;
    }

    @media (max-width: 450px) {  
        top: 16%;
    }

    @media (max-width: 374px) {  
        top:12.5%;

    }
}


.carousel-indicators [data-target]  {
    border: 1px solid map-get($theme-colors, black-);
    width: 5px;
    height: 5px;
    border-radius: 20px;

    &.active {
        background-color: map-get($theme-colors, black- );
        width: 21px;
    }
}

.vertical-divider {
    color: #333333;
    font-size: 1.5em;
    position: relative;
    top: -3px;
    margin: 0 .75rem;
}

.carousel-controls__buttons {

    width: 28px;

    @include media-breakpoint-down(sm) { 
        transform: scale(1.1);
     }

    button {
        position: absolute;
        border: none;
        background: none;
        width: auto;
        top:1px;        

        &:hover{
            opacity: 0.5;
        }
    }

    button.icon-play {
        display: none;
    }
}

.embed-responsive-item {
    max-height: 350px;
    width: 100%;

    @media (max-width: 520px) {  
        max-height: 280px;
    }

    @media (max-width: 450px) {  
        max-height: 220px;
    }

    @media (max-width: 375px) {  
        max-height: 240px;
        padding-top: 10px;
    }   

    @media (max-width: 350px) {  
        max-height: 210px;
        padding-top: 10px;
    }  
}

.btn.carousel_btn {
    width: auto;
    @include media-breakpoint-down(md) { 
        width: 100%;
        margin: 0;
     }
}