.browse-category-section{
    background-color: map-get($theme-colors, teal-light-1);
}

.container {    
  display: flex;    
  overflow-x: auto;
  padding-bottom: 1rem;
}

.category__filter_nav {
  display: flex;    
  padding: 0;
  // margin: 0 auto;
  margin-top: 0.5rem;
  position: relative;
  white-space: nowrap;
  overflow-x:scroll;
  overflow-y: visible;
  
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
  }
}

.category__filter_nav .category__filter_item {
  list-style-type: none;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:last-child{
    button {
      margin-right:0;
    }
  }
}

.category__filter_nav .category__filter_item .btn-category {  
    
  background-color: map-get($theme-colors, white-);
  margin-right: 1.05rem;
  width: 120px;
  border-radius: 0.75rem;
  border:none;
  box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.1);
  text-decoration: none;
  text-align: center;
  color: map-get($theme-colors, black-);
  bottom: 0;
  transition:all 0.2s ease-out;
  position: relative;

  &:hover {
    bottom: 0.5rem;
  }

  &:active {
    background-color: map-get($theme-colors, teal-light-2);
    border: 2px solid map-get($theme-colors, black-);
    bottom: 0;
  }
  &:disabled {
    pointer-events: all !important;
    opacity: 0.5;
  }

  &.active {    
    background-color: map-get($theme-colors, teal-light-2);
    border: 2px solid map-get($theme-colors, black-);
    bottom: 0; 
  }
  img {
    max-width: 70%;
    padding: 1.5rem 0;
  }

  h6 {
    margin-bottom: 1.5rem;
  }  
}

.container::-webkit-scrollbar {    
  display: none;
}

.browse-category__nav {
  // width: 120px;
  text-align: right;
  display: block;
}