#cartModal{
.modal {
    padding-right: 0!important;
        
    a {
        color:map-get($theme-colors, black-);
    }
}
.modal-open {
    padding-right: 0px !important;
}

.modal-dialog {
    transition: all 0.3s ease-out;
    transform: translate(0, 0);
    float: right;
}

.modal-dialog {
    max-width: 480px;
    height: 100%;
    margin: 0;

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }
}

.modal-content {
    height: 100%;
    display: inline-table;
    border: none;
    border-radius: 0;
}

.modal-header .btn-close.icon-cross {
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    &:before {
        padding: 0;
        margin: 12px 0 0 0;
    }
}

.modal-footer {
    box-shadow:  0px -4px 10px -2px rgba(0,0,0,0.1);
}

.cart_modal__promo, .cart_modal__item_info {
    background: map-get($theme-colors, teal-light-1);
    text-align: center;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.75rem;
    p {
        line-height: initial;
    }
}

.cart_modal__item_info {
    text-align: left;

    :before {
        font-size: 0.9rem;
        top: 4px;
        padding-right: 5px;
    }

    p {
        font-size: 1rem;
    }
}

.cart_modal__item_value {
    strong {
        font-family: "MazzardH-Bold";
    }
} 

.cart_modal__tableContent{
    width: 100%;
    th{
        font-weight: normal;
    }
    td {
        text-align: right;
    }
}

.cart_modal__item_list{
    list-style: none;
    padding: 0;

    .cart_modal__item {
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 1rem;
    }

    .cart_modal__item:last-child {
        border-bottom:none;
    }

    .cart_modal__item_img{
        position: relative;
        width: 100%;
        height: auto;
        display: block;
        background: map-get($theme-colors, teal-light-1);
        padding: 0.75rem;
        border-radius: 5px;
        border: 2px solid transparent; 
        margin-right: 1rem;
        
        
        img.image-overlay{
            width:50%;
            top:0;
            left:0;
            position:absolute;
          }      
    }    

    .cart_modal__item_title, .cart_modal__item_type {
        line-height: initial;
        margin-bottom: 0.4rem;
    }

    .cart_modal__item_title {
        margin-bottom: 0.15rem;
    }
}

.cart_modal__item_controls {
    text-align: right;
}

.cart-checkout-btn  {
    max-width: 100%;
}


}