@import 'bootstrap-datepicker.min.css';

// .datepicker {
//     text-align: center;
//     padding: 1rem;
// }

// .datepicker td {
//     padding: 0.25rem;
// }

div.ui-datepicker {
	background-color: #ffffff !important;
	padding: 10px;
	border: 1px solid rgba(0,0,0,.15) !important;
	border-radius: 0.25rem !important;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	display: none;
}

div.ui-datepicker select {
	border: 1px solid rgba(0,0,0,.15) !important;
	border-radius: 0.25rem !important;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	margin: 8px;
}

div.ui-datepicker span {
	margin-left: 4px;
	margin-right: 4px;
	color: #1d9ed4;
}

div.ui-datepicker-title .ui-datepicker-prev {
	margin-left: 5px;
	margin-right: 15px;
}