.content-feature-2-section {
    background: url(../images/content-feature-background.svg);
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.store-list-container {

    background: white;
    border-radius: 25px;
    box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.1);

    .row {
        border-bottom: 1px solid #cccccc;
        padding: .8rem 0 0.15rem 0;
        color:map-get($theme-colors, black-);

        &:last-child {
            border-bottom: none;
        }
    }

    .col-5 p {
        font-weight: bold;
        padding-right: 1rem;
        
    }

    .store-list__title {
        font-weight: bold;
    }

    .disclaimer {
        padding: 2rem 3rem 2rem 3rem;
        background-color:#F5F5F5;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}