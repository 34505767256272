.content-2col-section {
    background: url(../img/content-background.svg);
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-down(md) { 
        background: none;
    }   
}

.content-2col {
    background: map-get($theme-colors, white-);
    padding: 2rem;
    height: 100%;
    min-height: 500px;
    border-radius: 35px;
    box-shadow: 0px 6px 7px 1px rgba(0,0,0,0.1);

    .btn {
        width: 100%;
        max-width: 303px;
    }
}

.content-2col__title {
    text-align: center;
}

.content-2col__p {
    text-align: center;
    max-width: 400px;
}


