/* Generated by Glyphter (http://www.glyphter.com) on  Mon Mar 01 2021*/
@font-face {
    font-family: 'icons';
    src: url('../fonts/icons.eot');
    src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
         url('../fonts/icons.woff') format('woff'),
         url('../fonts/icons.ttf') format('truetype'),
         url('../fonts/icons.svg#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'icons';
   font-style: normal;
   font-weight: normal;
   line-height: 0;
   padding: 0 10px 0 0;
   top: 5px;
   position: relative;
   font-size: 1.25rem;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}

.icon-sm:before{
    font-size: 1rem;
}

.icon-right:before {
    padding: 0 0 0 8px;
    font-size: 2rem;
    top: 11px;
    line-height: 0;
}

.icon-right-aligned:before {
    left:10px;
}

.icon-alert:before{content:'\0041';}
.icon-arrow-cta:before{content:'\0042';}
.icon-arrow-down:before{content:'\0043';}
.icon-arrow-left:before{content:'\0044';}
.icon-arrow-right:before{content:'\0045';}
.icon-arrow-up:before{content:'\0046';}
.icon-card:before{content:'\0047';}
.icon-cart:before{content:'\0048';}
.icon-chevron-double:before{content:'\0049';}
.icon-chevron-down:before{content:'\004a';}
.icon-chevron-up:before{content:'\004b';}
.icon-contact:before{content:'\004c';}
.icon-cross:before{content:'\004d';}
.icon-edit:before{content:'\004e';}
.icon-email:before{content:'\004f';}
.icon-external-link:before{content:'\0050';}
.icon-filter:before{content:'\0051';}
.icon-hide:before{content:'\0052';}
.icon-in-store:before{content:'\0053';}
.icon-info:before{content:'\0054';}
.icon-menu:before{content:'\0055';}
.icon-minus:before{content:'\0056';}
.icon-online:before{content:'\0057';}
.icon-pause:before{content:'\0058';}
.icon-phone:before{content:'\0059';}
.icon-play:before{content:'\005a';}
.icon-plus:before{content:'\0061';}
.icon-question:before{content:'\0062';}
.icon-search:before{content:'\0063';}
.icon-tick:before{content:'\0064';}
.icon-user:before{content:'\0065';}

