.form-control {
  font-family: 'FuturaPT-Book';
}

#orderNav h1 {
  font-size: 1.5rem;
}

#orderNav li {
  color: map-get($theme-colors, grey-);
  margin-right: 2rem;

  &.active {
    color: map-get($theme-colors, black-);
  }
}

#updatecartbutton {
  margin: 0;
}

.cartSummaryHeading {
  color: map-get($theme-colors, black-);
}
.badge-info select {
  width: 100% !important;
  overflow: hidden;
}

.badge-info {
  background-color: map-get($theme-colors, teal-light-1);
}

.badge-info label {
  color: map-get($theme-colors, black-);
}

.badge-info h4,
.badge-info h5 {
  color: map-get($theme-colors, black-);
}

.badge-info .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: 0;
}

.badge-info .form-check-label {
  margin-bottom: 0;
}

.form-group-custom-radiobox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.form-group-custom-radiobox label {
  position: relative;
  cursor: pointer;
}

.form-group-custom-radiobox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid map-get($theme-colors, black-);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -5px;
}

.form-group-custom-radiobox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 5px;
  height: 12px;
  border: solid map-get($theme-colors, black-);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.buttonCollapse {
  position: absolute;
  right: 30px;
  top: 30px;
}

.thumb {
  max-width: 200px;
  display: inline-block;
  padding-top: 1rem;
  padding-right: 1rem;
}

.dec {
  display: inline-block;
  color: map-get($theme-colors, black-);
}

.editablecartitem .thumb {
  padding: 0;
}

.clearqnt {
  margin: 0;
  left: 0;
}

.table th,
.table td {
  padding: 0.5rem;
}

@include media-breakpoint-down(sm) {
  ul.orderstep {
    padding: 0;
    margin: 0;
    list-style: none;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    position: relative;
  }

  ul.orderstep li {
    float: left;
    width: 33.33%;
    height: 2.5rem;
    margin: 0;
  }

  ul.orderstep li:nth-child(2) {
    text-align: center;
  }

  ul.orderstep li:last-child {
    text-align: right;
  }

  ul.orderstep li span {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    border-radius: 100%;
    border: solid 1px map-get($theme-colors, black-);
    background: #fff;
    text-align: center;
    line-height: 2.5rem;
    color: map-get($theme-colors, black-);
  }

  ul.orderstep li.active span {
    background: map-get($theme-colors, black-);
    color: map-get($theme-colors, white-);
    border: none;
  }

  ul.orderstep:after {
    content: '';
    width: 100%;
    height: 7px;
    background: map-get($theme-colors, black-);
    display: block;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: -1;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: '';
  }
}

.australianCreditCardsOnly {
  padding: 0 1rem;
  color: map-get($theme-colors, validation-error);
}

.buttonCollapse .icon-chevron-up::before {
  padding: 0;
  top: initial;
}

.buttonCollapse span {
  position: relative;
  transform-origin: center;
}

.buttonCollapse.collapsed span {
  position: absolute;
  transform: rotate(180deg);
  left: -20px;
  top: -5px;
}

// custom select
$select-color: #fff;
$select-background: #c0392b;
$select-width: 100%;
$select-height: 40px;

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: map-get($theme-colors, black-);
  width: $select-width;
  height: $select-height;
}
.select-styled {
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 0;
  left: 0;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.5em + 1rem + 8px);
  padding: 0.6rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  @include transition(all 0.2s ease-in);
  &:after {
    content: '';
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: map-get($theme-colors, black-) transparent transparent
      transparent;
    position: absolute;
    top: 19px;
    right: 10px;
  }
  &:hover {
    // background-color: darken($select-background, 2);
  }
  &:active,
  &.active {
    background-color: map-get($theme-colors, teal-light-1);
    &:after {
      top: 9px;
      border-color: transparent transparent map-get($theme-colors, black-)
        transparent;
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: 48px;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: map-get($theme-colors, white-);
  li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    @include transition(all 0.15s ease-in);
    &:hover {
      color: map-get($theme-colors, black-);
      background: map-get($theme-colors, teal-light-1);
    }
    &[rel='hide'] {
      display: none;
    }

    &:last-child {
      border-bottom: 1px solid #ced4da;
    }
  }
}

.btn_primary.teal p :not(disabled),
.btn_secondary.teal p :not(disabled) {
  &:hover {
    background: map-get($theme-colors, teal-primary);
  }
}

.flybuys {
  background-image: url("../images/icons/flybuys-logo.svg");
  background-size: 70px 24px;
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding-left: 90px;
}

.loyaty-number {
    fieldset {
        display: block;
        margin-inline-start: 2px;
        margin-inline-end: 2px;
        padding-block-start: 0.425em;
        padding-inline-start: 0.75em;
        padding-inline-end: 0.75em;
        min-inline-size: min-content;
        border: 1px solid #949494;
        border-image: initial;
        line-height: 1.5;
        border-radius: 0.3rem;

        form-check-label {
            margin-bottom: 0.5em
        }
    }

    legend {
        max-width: fit-content;
        margin-left: -1em;
        margin-bottom: 0;
        padding-right: 0.25em
    }

    .fieldset-input {
        margin-bottom: 0.5em
    }

    .form-group-custom-radiobox {
        margin-bottom: 0.5em
    }

    .confirmation {
        display: none;
        padding-block-end: 0.625em;

        .container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            background-color: lightblue;
            border-radius: 0.3em;
            vertical-align: middle;
            width: 100%;
            padding-left: 0.25em;
            padding-right: 0.25em;
            padding-top: 0.25em;
            padding-bottom: 0.25em
        }

        .container label {
            font-size: 80%;
            font-weight: 700;
            margin-bottom: 0;
            overflow-wrap: break-word;
            text-anchor: start;
            padding-left: 0.4em;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            vertical-align: middle;
        }
    }
}