$cdnUrl: "https://cdndev.viicloud.com.au/Static/CI/ColesGroupV2";
$theme-colors: (teal-primary: #35d6da,
  teal-light-1: #dffcf4,
  teal-light-2: #d0efe6,
  teal-bg: #fefffb,
  grey-: #888888,
  black-: #1a1a1a,
  white-: white,
  yellow-: #ffd700,
  validation-error: #b00020,
  validation-success: #00854d,
);

$spacer: 1rem;

$spacers: (0: 0,
  1: calc($spacer / 4),
  2: calc($spacer / 2),
  3: $spacer,
  4: $spacer * 1.5,
  4a: $spacer * 2,
  4b: $spacer * 2.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 6,
);

$grid-gutter-width: 1.5rem;

$gutters: (0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.5,
  7: $spacer * 4,
);

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'typography';
@import 'icons';
@import 'header';
@import 'footer';
@import 'buttons';
@import 'carousel';
@import 'content-feature';
@import 'product-feature';
@import 'browse-category';
@import 'content-4col';
@import 'content-2col';
@import 'proof-points';
@import 'special-offer';
@import 'content-feature-2';
@import 'banner-2col';
@import 'content-2col-2cards';
@import 'filter';
@import 'hero-banner';
@import 'notice-bar';
@import 'navbar';
@import 'custom-table';
@import 'gift-card-detail';
@import 'cart-modal';
@import 'check-balance';
@import 'breadcrumb';
@import 'offer-disclaimer';
@import 'checkout';
@import 'datepicker';

@include media-breakpoint-up(xxl) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1264px;
  }
}

*,
*::before,
*::after {
  line-height: initial;
}

html,
body {
  background-color: map-get($theme-colors, teal-bg);
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  padding-top: 56px;
}

p {
  line-height: 1.5;
}

a {
  color: map-get($theme-colors, black-);
  text-decoration: underline;

  &:hover {
    color: map-get($theme-colors, black-);
  }
}

.nav-link {
  text-decoration: none;
}

.mb-6 {
  margin-bottom: 4rem;
}

.row {}

.is-off,
.hidden {
  display: none;
}

.disabled {
  cursor: none;
  opacity: 0.5;
}

.center {
  display: grid;
  place-items: center;
  min-height: 100%;
}

.divider {
  border-bottom: 1px solid #f1f1f1;
}

.styled-hr {
  border-color: #f1f1f1;
}

.line-behind-words__text {
  position: relative;
  background: url(../images/bg-behind-word.png);
  background-size: contain;
  line-height: 0;
}

.full-stop__black {
  color: map-get($theme-colors, black-);
}

.highlight-text__black {
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  background-color: #000;
  color: map-get($theme-colors, white-);
  font-weight: bold;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// #anchor{
//     position: relative;
//     top:-100px;
//     display: block;
//     height: 0;
//     width:0;
// }
// Offset fix for anchors
:target:before {
  content: '';
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

.badge-info {
  color: #1a1a1a;
}

a.underline {
  text-decoration: underline;
}

.error {
  color: #dc3545 !important;
}

.container-payment {
  max-width: 550px !important;
  margin-left: 20px !important;
}

div#threeDSUI {
  min-width: 250px;
  max-width: 600px;
  min-height: 400px;
  max-height: 600px;
}

div#threeDSUI div {
  min-width: 250px;
  max-width: 600px;
  min-height: 400px;
  max-height: 600px;
}

div#threeDSUI iframe {
  border: 1px solid #c9c9c9;
  min-width: 260px;
  max-width: 600px;
  min-height: 425px;
  max-height: 600px;
}

div#checkout-overlay-inprocess {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: wait;
  z-index: 2147483646;
  animation-duration: .3s;
  animation-name: easein;
  background: rgba(0, 0, 0, .8);
  font-family: Helvetica, sans-serif;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none;
}

.form-check-input:focus + .form-check-label {
  border: 1px solid #1a1a1a;
}


#SkipLinkFocus:focus,
#brands:focus,
li.active>h1:focus {
    outline: solid 1px #1d9ed4 !important;
    opacity: 1 !important;
}

a.skip-main {
  position:absolute;
  left: 0;
  top:0;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}

a.skip-main:focus, a.skip-main:active {
  color: #1a1a1a;
  top: 0;
  width: auto;
  height: auto;
  overflow:auto;
  border-radius: 10px;
  border:1px solid #1a1a1a;
  font-size:1.2em;
  z-index:9999;
}

.skip-main {
	position: absolute;
	left: -999px;
	z-index: -999;
	overflow: hidden;
}

ul.li-type-none li {
  list-style: none;
}

.carousel-control-next:focus {
  .btn_right {
    transform: scale(1.2);
    border: 2px solid black;
  }
}

.carousel-control-prev:focus {
  .btn_left {
    transform: scale(1.2);
    border: 2px solid black;
  }
}

.promotion-title {
  font-weight: bold;
}