.special-offer-section {
    display: flex; 
  }

.special-offer-section .container-xl {
    min-height: 440px;
    display: flex;
    flex-wrap: wrap;

    .special-offer-section__card {
        margin: 1px;
        flex-grow: 1;
        width: 325px;
    }

    .special-offer-section__row {
        border-radius: 30px;
        background: map-get($theme-colors, white-);
        box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.1);

        .special-offer__badge-container {
            width: 100%;
            position: relative;
            height: 65px;
            margin-top: -24px;
            margin-bottom: -41px;

            .special-offer__badge-header {
                position: absolute;
                display: block;
                text-align: right;
                width: 130px;
                top: 0;
                right: 0;
                height: 65px;
            }
        }
    }

    .content-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .special-offer__badge {
        background: #ffd700;
        color: #1a1a1a;
        text-transform: uppercase;
        display: block;
        max-width: 130px;
        padding: 0.3rem 0.5rem;
        font-weight: bold;
        border-radius: 5px 0 0 5px;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right; /*Takes it out of normal doc flow */
    }

    .special-offer__badgeicon-container {
        width: 100%;
        position: relative;
        margin-bottom: -41px;

        .special-offer__badge {
            position: absolute;
            bottom: 0;
            right: 0;
            text-align: right;
            border-radius: 5px 0 0 5px;
        }

        .special-offer__badgeicon {
            float: left;
            padding-left: 50px;
            margin-top: -89px;
        }
    }

    .special-offer__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width:768px) {
            max-width: 70%;
        }
    }

    .special-offer__star {
        display: inline-block;
    }

    .special-offer-section__img {
        overflow: hidden;

        img {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 485px;
            width: 80%;

            @media (max-width: 575px) {
                max-height: 485px;
            }

            @media (max-width: 425px) {
                max-height: 380px;
            }

            @media (max-width:380px) {
                max-height: 320px;
            }
        }
    }

    .special-offer__disclaimer__row {
        justify-content: center;
    }

    .special-offer__disclaimer {
        color: map-get($theme-colors, black-);
        width: 80%;
    }
}
